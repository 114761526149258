<template>
  <v-app class="auth">
    <layout-header class="auth__layout-header" />

    <v-main class="auth__main">
      <v-container fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <error-page v-if="httpErrorCode" :code="httpErrorCode" />
            <router-view v-else />
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>

    <layout-footer />
  </v-app>
</template>

<script>
// Components
import LayoutHeader from '@/components/layout/Header/index.vue';
import LayoutFooter from '@/components/layout/Footer.vue';
import ErrorPage from '@/components/Error.vue';

// Services
import errorService from '@/services/error';

export default {
  name: 'AuthLayout',

  components: { LayoutHeader, LayoutFooter, ErrorPage },

  props: {
    routeName: { type: String, required: true },
  },

  data() {
    return {
      httpErrorCode: undefined,
    };
  },

  watch: {
    routeName: {
      handler() {
        this.httpErrorCode = undefined;
      },
    },
  },

  destroyed() {
    errorService.unsubscribe(this.handleError);
  },

  beforeMount() {
    errorService.subscribe(this.handleError);
  },

  methods: {
    handleError(code) {
      this.httpErrorCode = code;
    },
  },
};
</script>

<style lang="scss">
.app {
  .auth {
    &__layout-header {
      @media (min-width: map.get($--screens, 'sm')) {
        padding-left: 64px !important;
        padding-right: 64px !important;
      }

      @media (min-width: map.get($--screens, 'md')) {
        padding-left: 160px !important;
        padding-right: 160px !important;
      }
    }

    &__main {
      padding-top: 80px !important;

      @media (min-width: map.get($--screens, 'sm')) {
        padding-top: 110px !important;
      }
    }
  }
}
</style>
